import { Alert, Dialog, Slide, Snackbar } from "@mui/material";
import Hls from "hls.js";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  arrowDown,
  close,
  comments,
  likes,
  redHeart,
  robo,
} from "../../assets/images";
import api from "../../Intercepters/AuthIntercepter";
import { useUser } from "../../state/UserContext";
import style from "./cardGrid.module.scss";
import Comment from "../../components/comments/Comment";

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

const VideoDetailsModal = ({
  open,
  onClose,
  videoData,
  videosData,
  selectedVideoIndex,
  setSelectedVideo,
  setSelectedVideoIndex,
}) => {
  const videoRef = useRef(null);
  const { userData } = useUser();
  const [isFollowed, setIsFollowed] = useState("Follow");
  const [likesValue, setLikesValue] = useState(videoData?.likes);
  const [commentsValue, setCommentsValue] = useState(videoData?.comments);
  const [videoUrl, setVideoUrl] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (videoData?.product.followers.includes(userData._id)) {
      setIsFollowed("Followed");
    } else {
      setIsFollowed("Follow");
    }
  }, []);

  useEffect(() => {
    const fetchVideoUrl = async () => {
      try {
        const { data } = await api.get(`/videos/video-url/${videoData._id}`);
        if (data && data.videoUrl) {
          setVideoUrl(data.videoUrl);
          return data.videoUrl;
        }
      } catch (error) {
        console.error("Error fetching video URL:", error);
        return null;
      }
    };
    if (videoData) {
      fetchVideoUrl();
    }
  }, [videoData]);

  useEffect(() => {
    setCommentsValue(videoData?.comments);
    setLikesValue(videoData?.likes);
  }, [videoData]);

  useEffect(() => {
    let hls;
    if (videoUrl && Hls.isSupported() && videoRef.current) {
      hls = new Hls();
      hls.loadSource(videoUrl);
      hls.attachMedia(videoRef.current);

      return () => {
        hls.destroy();
      };
    } else if (videoRef.current) {
      videoRef.current.src = videoUrl;
    }
  }, [videoUrl]);

  const updateUrl = () => {
    const url = generateUrl();
    window.history.pushState(null, "", url);
  };

  useEffect(() => {
    updateUrl();
  }, [videoUrl]);

  const generateUrl = () => {
     return `${process.env.REACT_APP_API_URL}/${videoData.product.productId}/video/${videoData._id}`;
  };

  const handleCopyLink = () => {
    const url = generateUrl();
    try {
      navigator.clipboard.writeText(url);

      setOpenSnackbar(true);
      setMessage("Link copied to clipboard!");
    } catch (err) {
      setOpenSnackbar(true);
      setMessage("Failed to copy URL");
      console.error("Failed to copy text: ", err);
    }
  };

  // Navigate to the previous video if it exists
  const handlePrevVideo = () => {
    if (selectedVideoIndex > 0) {
      const prevIndex = selectedVideoIndex - 1;
      setSelectedVideo(videosData[prevIndex]);
      setSelectedVideoIndex(prevIndex);
    }
  };

  // Navigate to the next video if it exists
  const handleNextVideo = () => {
    if (selectedVideoIndex < videosData.length - 1) {
      const nextIndex = selectedVideoIndex + 1;
      setSelectedVideo(videosData[nextIndex]);
      setSelectedVideoIndex(nextIndex);
    }
  };

  if (!videoData) return null;

  const followProduct = async () => {
    try {
      const response = await api.post(`/products/add-follow`, {
        userId: userData._id,
        productId: videoData?.product._id,
      });
      console.log(userData._id);
      console.log(videoData?.product._id);
      console.log(response);
      if (response.status === 201) {
        setIsFollowed(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const removeFollowProduct = async () => {
    try {
      const response = await api.post(`/products/remove-follow`, {
        userId: userData._id,
        productId: videoData?.product._id,
      });
      if (response.status === 201) {
        setIsFollowed(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFollow = async () => {
    if (isFollowed) {
      await removeFollowProduct();
    } else {
      await followProduct();
    }
  };

  function formatNumber(num) {
    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "B";
    }
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
    }
    return num.toString();
  }

  const handleLike = async () => {
    try {
      const response = await api.put(
        `${process.env.REACT_APP_API_URL}/videos/Mobile_IOS/likeVideo/${videoData._id}`,
        { userId: userData?._id }
      );
      setLikesValue(response.data.likes);
    } catch (error) {}
  };

  const handleHeartClick = (e) => {
    e.stopPropagation();
    userData && handleLike();
  };

  const updateCommentCount = () => {
    setCommentsValue((prev) => [...prev, {}]);
  };

  const handleCommentDeleted = () => {
    setCommentsValue((prevComments) => prevComments.slice(0, -1));
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => {
        window.history.pushState(null, "", "/");
        onClose();
      }}
      sx={{ overflow: "hidden" }}
    >
      <button
        onClick={() => {
          window.history.pushState(null, "", "/");
          onClose();
        }}
        style={{
          position: "absolute",
          top: "1.2rem",
          left: "1.2rem",
          backgroundColor: "rgba(84, 84, 84, 0.5)",
          borderRadius: "50%",
          padding: "5px",
          width: "40px",
          height: "40px",
          cursor: "pointer",
          zIndex: "10000",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="24"
          height="24"
          viewBox="0 0 50 50"
        >
          <path
            d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z"
            fill="white"
          ></path>
        </svg>
      </button>

      <div className={style["container-video-details"]}>
        <div className={style["video-container"]}>
          {/* Blurred Background */}
          <div
            className={style["blurred-background"]}
            style={{
              backgroundImage: `url(${videoData?.capture})`,
            }}
          ></div>
          <div className={style["arrows"]}>
            {selectedVideoIndex > 0 && (
              <button
                onClick={handlePrevVideo}
                style={{
                  zIndex: 10,
                  backgroundColor: "rgba(84, 84, 84, 0.5)",
                  borderRadius: "50%",
                  padding: "10px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                  <path
                    d="m12 6.586-8.707 8.707 1.414 1.414L12 9.414l7.293 7.293 1.414-1.414L12 6.586z"
                    fill="white"
                  />
                </svg>
              </button>
            )}

            {selectedVideoIndex < videosData?.length - 1 && (
              <button
                onClick={handleNextVideo}
                style={{
                  zIndex: 10,
                  backgroundColor: "rgba(84, 84, 84, 0.5)",
                  borderRadius: "50%",
                  padding: "10px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                  <path
                    d="M12 17.414 3.293 8.707l1.414-1.414L12 14.586l7.293-7.293 1.414 1.414L12 17.414z"
                    fill="white"
                  />
                </svg>{" "}
              </button>
            )}
          </div>

          {/* Video Element */}
          <video className={style["video"]} controls autoPlay ref={videoRef}>
            Your browser does not support the video tag.
          </video>
        </div>
        <div className={style["video-info"]}>
          <div className={style["product-info"]}>
            <Link
              key={videoData?.productId}
              className={style.product}
              to={`/product-details/${videoData?.product?._id}`}
            >
              {videoData?.product?.image ? (
                <img
                  src={
                    videoData?.product?.image?.includes("uploads")
                      ? `${process.env.REACT_APP_API_URL}/${videoData?.product?.image}`
                      : videoData?.product?.image
                  }
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = robo;
                  }}
                  alt="product-logo"
                ></img>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="42"
                  height="42"
                  viewBox="0 0 42 42"
                  fill="none"
                ></svg>
              )}
              <div className={style["name"]}>
                <span className={style["product-name"]}>
                  {videoData?.product?.name}
                </span>
                <span style={{ color: " #F6552DB5" }}>
                  {videoData?.category}
                </span>
              </div>
            </Link>
            <button className={style["follow-button"]} onClick={handleFollow}>
              {isFollowed}
            </button>
          </div>

          <div className={style["values"]}>
            <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
              <button onClick={handleHeartClick}>
                <img src={likes} alt="likes" className={style["likes"]} />
              </button>
              <span>{formatNumber(likesValue.length)}</span>
            </div>
            <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
              <img src={comments} alt="comments" className={style["likes"]} />
              <span>{formatNumber(commentsValue.length)}</span>
            </div>
          </div>

          <div className={style["copy-container"]}>
            <span>{generateUrl()}</span>
            <button onClick={handleCopyLink}>Copy link</button>
          </div>

          <span
            style={{
              textDecoration: "underline",
              fontWeight: "600",
            }}
          >
            Comments
          </span>
          <Comment
            videoId={videoData._id}
            productId={videoData?.product?._id}
            onCommentAdded={updateCommentCount}
            onCommentDeleted={handleCommentDeleted}
          ></Comment>
        </div>
      </div>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default VideoDetailsModal;
