import React, { useEffect, useRef, useState } from "react";
import style from "./videoDetails.module.scss";
import {
  back_button,
  crossButton,
  grayHeart,
  redHeart,
} from "../../assets/images/index";
import Comment from "../../components/comments/Comment";
import axios from "axios";
import { useUser } from "../../state/UserContext";
import { useFetcher, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { robo } from "../../assets/images";
import Hls from "hls.js";
function VideoDetails() {
  const videoId = useParams().id;

  const navigate = useNavigate();
  const [isFollowed, setIsFollowed] = useState(false);
  const [video, setVideo] = useState(null);
  const [views, setViews] = useState(0);
  const [productFollowers, setProductFollowers] = useState([]);
  const [businessOwnerVideos, setBusinessOwnerVideos] = useState([]);
  const { userData } = useUser();

  const isBusinessOwner = JSON.parse(
    window.localStorage.getItem("isBusinessOwner")
  );

  const getBusinessOwnerVideos = async () => {
    if (isBusinessOwner) {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/videos/video-by-business-owner/${userData._id}`
      );
      const response = await res.json();
      setBusinessOwnerVideos(response.map((video) => video.videoId));
    }
  };

  const formatDate = (inputdate) => {
    const date = new Date(inputdate);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Months are zero-based
    const day = date.getDate();
    return `${year}-${month}-${day}`;
  };

  const getVideoInformation = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/videos/video-by-id/${videoId}`
      );
      const response = await res.json();
      if (response) {
        console.log(response);
        setVideo(response);
        return response;
      }
    } catch (error) {}
  };

  const videoRef = useRef(null);

  useEffect(() => {
    const getVideoInformation = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_URL}/videos/video-by-id/${videoId}`
        );
        const response = await res.json();
        if (response) {
          setVideo(response);
        }
      } catch (error) {
        console.error("Error fetching video information:", error);
      }
    };

    getVideoInformation();
  }, [videoId]);

  useEffect(() => {
    let hls;

    const initializeHls = () => {
      if (video?.videoURL && Hls.isSupported() && videoRef.current) {
        hls = new Hls();
        hls.loadSource(video.videoURL);
        hls.attachMedia(videoRef.current);
      } else if (videoRef.current && video?.videoURL) {
        videoRef.current.src = video.videoURL; // For native HLS playback
      }
    };

    if (video?.videoURL) {
      initializeHls();
    }

    // Cleanup function to destroy Hls instance when component unmounts or video changes
    return () => {
      if (hls) {
        hls.destroy();
      }
    };
  }, [video?.videoURL]);

  const getProductInformation = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/products/${video?.productId}`
      );
      if (response) return response.data;
    } catch (error) {
      return null;
    }
  };

  const followProduct = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/products/add-follow`,
        { userId: userData._id, productId: video?.product._id }
      );
      if (response.status === 201) {
        setIsFollowed(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const removeFollowProduct = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/products/remove-follow`,
        { userId: userData._id, productId: video?.product._id }
      );
      if (response.status === 201) {
        setIsFollowed(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (video?.product.followers.includes(userData._id)) {
      setIsFollowed(true);
    } else {
      setIsFollowed(false);
    }
  }, [video?.product.followers]);
  const handleFollow = async () => {
    if (video?.product?.followers.includes(userData._id)) {
      await removeFollowProduct();
    } else {
      await followProduct();
    }
  };

  const closeHandle = () => {
    const activeItem = localStorage.getItem("activeItem");
    if (activeItem === "My Space") navigate("/my-space");
    else navigate("/");
  };

  const [isLiked, setIsLiked] = useState(false);
  const [likes, setLikes] = useState(0);

  useEffect(() => {
    if (video) {
      setLikes(video?.likes?.length || 0);
      setIsLiked(video?.likes?.includes(userData?._id));
    }
  }, [video]);

  const addUpVote = async () => {
    try {
      setIsLiked(true);
      setLikes(likes + 1);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/posts/add-upvote`,
        { userId: userData?._id, videoId: videoId }
      );
      if (response.status === 201) {
        setLikes(response.data.likes.length);
      }
    } catch (error) {
      setIsLiked(false);
      setLikes(likes - 1);
    }
  };

  const removeUpVote = async () => {
    try {
      setIsLiked(false);
      setLikes(likes - 1);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/posts/remove-upvote`,
        { userId: userData?._id, videoId: videoId }
      );
      if (response.status === 201) {
        setLikes(response.data.likes.length);
      }
    } catch (error) {
      setIsLiked(true);
      setLikes(likes + 1);
    }
  };

  async function handleLikeButtonClick() {
    if (video.likes?.includes(userData?._id)) {
      await removeUpVote();
    } else {
      await addUpVote();
    }
  }

  return (
    <div className={style["wrapper"]}>
      <div className={style["close-button-container"]}>
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={closeHandle}
        >
          <img
            src={back_button}
            alt="close"
            className={style["close-button"]}
          />{" "}
          <span style={{ fontWeight: "500", fontSize: "16px" }}>Back</span>
        </div>
      </div>
      <div className={style["video-page-container"]}>
        <div className={style["section-1"]}>
          <div className={style["video-container"]}>
            <video controls src={video?.videoURL} ref={videoRef}>
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
        <div className={style["section-2"]}>
          <div className={style["details-container"]}>
            <div className={style["details-video"]}>
              <Link
                key={video?.productId}
                className={style.product}
                to={`/product-details/${video?.product?._id}`}
              >
                {video?.product?.image ? (
                  <img
                    src={`${process.env.REACT_APP_API_URL}/${video?.product?.image}`}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = robo;
                    }}
                    alt="product-logo"
                  ></img>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="42"
                    height="42"
                    viewBox="0 0 42 42"
                    fill="none"
                  ></svg>
                )}
              </Link>
              <div className={style["product-information"]}>
                <Link
                  key={video?.product?._id}
                  className={style.product}
                  to={`/product-details/${video?.product?._id}`}
                >
                  <span className={style["product-name"]}>
                    {video?.product?.name}
                  </span>{" "}
                </Link>
                <p>
                  <span style={{ color: " #F6552DB5" }}>{video?.category}</span>{" "}
                  {views} views. {formatDate(video?.created_at)}
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginRight: "18px",
                gap: "10px",
              }}
            >
              {!businessOwnerVideos.includes(videoId) && (
                <button
                  className={style["follow-button"]}
                  onClick={handleFollow}
                >
                  {isFollowed
                    ? "Followed"
                    : productFollowers.includes(userData._id)
                    ? "Followed"
                    : "Follow"}
                </button>
              )}
              <span
                style={{
                  fontWeight: "400",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <span>{likes} </span>
                <button>
                  <img
                    src={isLiked ? redHeart : grayHeart}
                    onClick={handleLikeButtonClick}
                    alt="like button"
                    style={{
                      width: "20px",
                      height: "20px",
                      minWidth: "20px",
                      minHeight: "20px",
                    }}
                  />
                </button>
              </span>
            </div>
          </div>
          <span style={{ textDecoration: "underline" }}>Comments</span>
          <Comment videoId={videoId} productId={video?.product?._id}></Comment>
        </div>
      </div>
    </div>
  );
}

export default VideoDetails;
