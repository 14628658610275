import React, { useContext, useState } from "react";
import { useNavigate } from "react-router";
import style from "./MobileMenu.module.scss";
import {
  productIcon,
  productIconActive,
  homeIcon,
  homeIconActive,
  videoListIcon,
  videoListIconActive,
  uploadIcon,
  recordIcon,
} from "../../assets/images";
import { Context } from "../../state/UserContext";
import { MenuContext } from "../../pages/Layout";

const MobileMenu = () => {
  const [activeItem, setActiveItem] = useState(
    localStorage.getItem("activeItem") || ""
  );
  const { openProductPopUp, setOpenProductPopUp } = useContext(Context);
  const navigate = useNavigate();

  const menuItems = [
    { title: "Explore", icon: [homeIcon, homeIconActive], link: "/" },
    {
      title: "Product Store",
      icon: [productIcon, productIconActive],
      link: "/products-store",
    },
    {
      title: "My Space",
      icon: [videoListIcon, videoListIconActive],
      link: "/my-space",
    },
  ];

  const handleNavigation = (item) => {
    setActiveItem(item.title);
    navigate(item.link);
    localStorage.setItem("activeItem", item.title);
  };

  return (
    <div className={style["bottom-menu"]}>
      {menuItems.map((item, index) => {
        const isActive = activeItem === item.title;
        return (
          <div
            key={index}
            className={
              isActive ? style["menu-item-active"] : style["menu-item"]
            }
            onClick={() => handleNavigation(item)}
          >
            <img src={item.icon[isActive ? 1 : 0]} alt={item.title} />
            <span>{item.title}</span>
          </div>
        );
      })}
    </div>
  );
};

export default MobileMenu;
