import React, { useEffect, useRef, useState } from "react";
import VideoEditor from "../../../components/video-trimming/VideoEditor";
import style from "./uploadVideo.module.scss";
import { useNavigate } from "react-router-dom";
import { uploadVideo } from "../../../assets/images";
import { Checkbox, CircularProgress, FormControlLabel } from "@mui/material";
import CustomSnackbar from "../../../components/snackbar/CustomSnackbar";
import api from "../../../Intercepters/AuthIntercepter";
import FrameExtractor from "../video-page/FrameExtractor";

const UploadVideo = () => {
  const [product, setProduct] = useState(null);
  const [user, setUser] = useState(null);
  const [type, setType] = useState("Release");
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [videoFile, setVideoFile] = useState(null);
  const [showTrimmingSlider, setShowTrimmingSlider] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [openSnackbar, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isPublishedDirectly, setIsPublishedDirectly] = useState(true);
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [frames, setFrames] = useState([]);
  const [selectedFrame, setSelectedFrame] = useState(null);

  const editIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M4.17521 21C3.82521 21.0834 3.52105 20.9959 3.26271 20.7375C3.00438 20.4792 2.91688 20.175 3.00021 19.825L4.00021 15.05L8.95021 20L4.17521 21ZM8.95021 20L4.00021 15.05L15.4502 3.60002C15.8335 3.21669 16.3085 3.02502 16.8752 3.02502C17.4419 3.02502 17.9169 3.21669 18.3002 3.60002L20.4002 5.70002C20.7835 6.08336 20.9752 6.55836 20.9752 7.12502C20.9752 7.69169 20.7835 8.16669 20.4002 8.55002L8.95021 20ZM16.8752 5.00002L6.52521 15.35L8.65021 17.475L19.0002 7.12502L16.8752 5.00002Z"
        fill="#F6552D"
      />
    </svg>
  );

  const cutIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M19 21L12 14L9.65 16.35C9.78333 16.6 9.875 16.8667 9.925 17.15C9.975 17.4333 10 17.7167 10 18C10 19.1 9.60833 20.0417 8.825 20.825C8.04167 21.6083 7.1 22 6 22C4.9 22 3.95833 21.6083 3.175 20.825C2.39167 20.0417 2 19.1 2 18C2 16.9 2.39167 15.9583 3.175 15.175C3.95833 14.3917 4.9 14 6 14C6.28333 14 6.56667 14.025 6.85 14.075C7.13333 14.125 7.4 14.2167 7.65 14.35L10 12L7.65 9.65C7.4 9.78333 7.13333 9.875 6.85 9.925C6.56667 9.975 6.28333 10 6 10C4.9 10 3.95833 9.60833 3.175 8.825C2.39167 8.04167 2 7.1 2 6C2 4.9 2.39167 3.95833 3.175 3.175C3.95833 2.39167 4.9 2 6 2C7.1 2 8.04167 2.39167 8.825 3.175C9.60833 3.95833 10 4.9 10 6C10 6.28333 9.975 6.56667 9.925 6.85C9.875 7.13333 9.78333 7.4 9.65 7.65L22 20V21H19ZM15 11L13 9L19 3H22V4L15 11ZM6 8C6.55 8 7.02083 7.80417 7.4125 7.4125C7.80417 7.02083 8 6.55 8 6C8 5.45 7.80417 4.97917 7.4125 4.5875C7.02083 4.19583 6.55 4 6 4C5.45 4 4.97917 4.19583 4.5875 4.5875C4.19583 4.97917 4 5.45 4 6C4 6.55 4.19583 7.02083 4.5875 7.4125C4.97917 7.80417 5.45 8 6 8ZM12 12.5C12.1333 12.5 12.25 12.45 12.35 12.35C12.45 12.25 12.5 12.1333 12.5 12C12.5 11.8667 12.45 11.75 12.35 11.65C12.25 11.55 12.1333 11.5 12 11.5C11.8667 11.5 11.75 11.55 11.65 11.65C11.55 11.75 11.5 11.8667 11.5 12C11.5 12.1333 11.55 12.25 11.65 12.35C11.75 12.45 11.8667 12.5 12 12.5ZM6 20C6.55 20 7.02083 19.8042 7.4125 19.4125C7.80417 19.0208 8 18.55 8 18C8 17.45 7.80417 16.9792 7.4125 16.5875C7.02083 16.1958 6.55 16 6 16C5.45 16 4.97917 16.1958 4.5875 16.5875C4.19583 16.9792 4 17.45 4 18C4 18.55 4.19583 19.0208 4.5875 19.4125C4.97917 19.8042 5.45 20 6 20Z"
        fill="#F6552D"
      />
    </svg>
  );

  const handleFramesExtracted = (frames) => {
    setFrames(frames);
  };
  const handleFrameSelected = (frame) => {
    setSelectedFrame(frame);
  };

  useEffect(() => {
    const storedProduct = localStorage.getItem("selectedProduct");
    const parsedProduct = storedProduct ? JSON.parse(storedProduct) : null;
    const userData = JSON.parse(window.localStorage.getItem("user"));

    setProduct(parsedProduct);
    setUser(userData);

    if (videoFile && videoRef.current) {
      const videoURL = URL.createObjectURL(videoFile);
      videoRef.current.src = videoURL;
      videoRef.current.play().catch((error) => {
        console.error("Error playing the video:", error);
      });

      return () => {
        URL.revokeObjectURL(videoURL);
        videoRef.current.src = "";
      };
    }
  }, [videoFile]);

  const handleSnackbarOpen = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const [urlVide, setUrlVideo] = useState("");

  const handleVideoChange = (event) => {
    const file = event.target.files[0];
    const maxSizeInBytes = 70 * 1024 * 1024;
    const validTypes = [
      "video/mp4",
      "video/avi",
      "video/mkv",
      "video/quicktime",
    ];
    if (!validTypes.includes(file?.type)) {
      handleSnackbarOpen(
        "Invalid file type. Please upload a valid video type (mp4,avi,mkv,MOV )."
      );
      return;
    } else if (file) {
      if (file?.size > maxSizeInBytes) {
        handleSnackbarOpen(
          "The video file size exceeds the 70 MB limit. Please upload a shorter Video."
        );
        return;
      }
      const videoUrl = URL.createObjectURL(file);
      setUrlVideo(videoUrl);
      setVideoFile(file);
    }
  };

  const triggerFileInput = () => {
    document.getElementById("video-upload").click();
  };

  // const retrieveVideoUrl = () => {
  //   const storedVideoUrl = localStorage.getItem("videoUrl") || sessionStorage.getItem("videoUrl");
  //   console.log(storedVideoUrl);
  //   if (storedVideoUrl) {
  //     fetch(storedVideoUrl)
  //       .then(res => res.blob())
  //       .then(blob => {
  //         const file = new File([blob], "retrievedVideo.mp4", { type: blob.type });
  //         setVideoFile(file);
  //       })
  //       .catch(error => console.error("Error retrieving video URL:", error));
  //   }
  // };

  // useEffect(() => {
  //   retrieveVideoUrl();
  // }, []);

  // useEffect(() => {
  //   console.log('videoFile from uploadVideo: ', videoFile);
  //   retrieveVideoUrl();
  // }, [videoFile]);

  const handleTrimming = () => {
    setShowTrimmingSlider(!showTrimmingSlider);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!product || (!videoFile && !localStorage.getItem("trimmedVideoUrl"))) {
      console.error("Product or video is missing.");
      return;
    }
    setIsLoading(true);
    setIsDisabled(true);
    setUploadProgress(0);
    const formData = new FormData();
    formData.append("product", JSON.stringify(product));
    formData.append("productId", product?._id);
    formData.append("videos_type", type);
    formData.append("description", description);
    formData.append("title", title);
    formData.append("is_recorded", false);
    formData.append("is_published", isPublishedDirectly);

    if (selectedFrame) {
      const blobResponse = await fetch(selectedFrame);
      const blobRes = await blobResponse.blob();
      formData.append("capture", blobRes, "capture.png");
    }

    const videoUrl = localStorage.getItem("trimmedVideoUrl");
    if (videoUrl) {
      try {
        const response = await fetch(videoUrl);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const videoBlob = await response.blob();
        formData.append("video", videoBlob, "video.mp4");
      } catch (error) {
        console.error("Error fetching the video blob:", error);
      }
    } else if (videoFile) {
      formData.append("video", videoFile);
    } else {
      console.error(
        "No video URL found in localStorage and no video file provided"
      );
      return;
    }
    try {
      setIsDisabled(true);
      const response = await api.post(
        `/videos/create-video/${product._id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentage);
          },
        }
      );
      if (!response.statusText == "OK") {
        throw new Error("Error submitting the form.");
      }

      // const videos_list = await getAllVideos();
      // sessionStorage.setItem("videosData", JSON.stringify(videos_list));

      setShowPopup(true);

      navigate(`/product-details/${product?._id}`);
      localStorage.setItem("selectedProduct", {});
      localStorage.removeItem("trimmedVideoUrl");
    } catch (error) {
      console.error("Error creating video:", error);
    } finally {
      setIsLoading(false);
      setIsDisabled(false);
      setUploadProgress(0);
    }
  };

  const handleDeleteVideo = () => {
    if (videoFile) {
      setVideoFile(null);
      localStorage.removeItem("videoUrl");
      window.location.href = "/";
    }
  };

  function CircularProgressWithLabel(props) {
    return (
      <div
        sx={{
          position: "relative",
          display: "inline-flex",
          color: "#fff",
          backgroundColor: "#fff",
        }}
      >
        <CircularProgress
          variant="determinate"
          {...props}
          sx={{
            color: "#fff",
          }}
        />
      </div>
    );
  }

  return (
    <div className={`${style["video-page"]}`}>
      <div
        className={`${style["video-container"]}`}
        style={{
          // minHeight: videoFile || videoFile !== null ? "632px" : "250px",
          padding: "20px",
        }}
      >
        {(videoFile || videoFile !== null) && (
          <div className={`${style["edit-buttons"]}`}>
            {/* <span>{editIcon}</span> */}

            <span onClick={handleTrimming}>{cutIcon}</span>
          </div>
        )}
        {!videoFile || videoFile === null ? (
          <div
            style={{
              backgroundColor: "#fff",
              boxShadow: "0px 1px 5px 0px rgba(100, 116, 139, 0.12)",
              padding: "24px",
              borderRadius: "8px",
              margin: "32px",
            }}
          >
            <div
              className={`${style["file-upload-container"]}`}
              onClick={() => triggerFileInput()}
            >
              <div className={`${style["upload-video-button"]}`}>
                <img
                  src={uploadVideo}
                  alt="Upload Video"
                  style={{
                    backgroundColor: "rgba(19, 109, 199, 0.10)",
                    padding: "8px 15px",
                    borderRadius: "50px",
                    fill: "#E86747",
                    stroke: "#E86747",
                  }}
                />
                <span
                  className={style["span-title"]}
                  style={{
                    color: "#052443",
                    fontSize: "16px",
                    fontFamily: "Inter,sans-serif",
                    fontWeight: "400",
                    marginLeft: "4px",
                  }}
                >
                  <span style={{ color: "#E86747", marginRight: "3px" }}>
                    Click here{" "}
                  </span>
                  to upload a video
                </span>
              </div>
              <input
                type="file"
                id="video-upload"
                accept="video/*"
                onChange={handleVideoChange}
                style={{ display: "none" }}
              />
            </div>
          </div>
        ) : (
          <VideoEditor
            file={videoFile}
            videoFile={urlVide}
            className={`${style["player-video"]}`}
            showSlider={showTrimmingSlider}
          />
        )}
      </div>
      <div className={`${style["video-info"]}`}>
        <div className={`${style["info-header"]}`}>
          <div className={`${style["avatar-container"]}`}>
            <img
              src={
                product && product.image.includes("upload")
                  ? `${process.env.REACT_APP_API_URL}/${product.image}`
                  : product?.image
              }
              alt="avatar"
            />
          </div>
          <div className={`${style["info-user-name"]}`}>
            <span className={`${style["video-name"]}`}>{product?.name}</span>
            <span className={`${style["user-name"]}`}>
              by {user?.firstName}
            </span>
          </div>
        </div>
        <div className={`${style["form-container"]}`}>
          <form onSubmit={handleSubmit}>
            <div className={`${style["first-row"]}`}>
              <div className={`${style["select-container"]}`}>
                <label htmlFor="product">Product</label>
                <div className={`${style["select"]}`}>
                  <select
                    value={product ? product.name : ""}
                    onChange={(e) => setProduct(e.target.value)}
                    disabled
                  >
                    <option value={product?.name}>
                      {product ? product.name : ""}
                    </option>
                  </select>
                </div>
              </div>
              <div className={`${style["select-container"]}`}>
                <label htmlFor="type">Type Video</label>
                <div className={`${style["select"]}`}>
                  <select
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  >
                    <option value="MVP">MVP</option>
                    <option value="Release">Release</option>
                    <option value="Launch">Launch</option>
                    <option value="Guide">Guide</option>
                    <option value="Pitch">Pitch</option>
                  </select>
                </div>
              </div>
            </div>

            <div style={{ margin: " 10px 0" }}>
              <label htmlFor="type">
                Select an image from your video to use as a thumbnail (Please
                wait...)
              </label>
              <div
                style={{ display: "flex", overflowX: "auto", marginTop: "5px" }}
              >
                {videoFile && (
                  <FrameExtractor
                    videoUrl={videoFile}
                    onFramesExtracted={handleFramesExtracted}
                    onFrameSelected={handleFrameSelected}
                  />
                )}
              </div>
            </div>

            <div className={`${style["second-row"]}`}>
              <label htmlFor="title">Title</label>
              <input
                type="text"
                required
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>

            <div className={`${style["second-row"]}`}>
              <label htmlFor="description">Description</label>
              <textarea
                type="text"
                rows="4"
                required
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div style={{ marginTop: "12px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isPublishedDirectly}
                    onChange={(e) => setIsPublishedDirectly(e.target.checked)}
                    sx={{
                      color: "#e86747",
                      "&.Mui-checked": {
                        color: "#e86747",
                      },
                    }}
                  />
                }
                label="Publish directly to Recolyse Feeds"
              />
            </div>

            <div className={`${style["button-container"]}`}>
              <button
                onClick={handleDeleteVideo}
                disabled={isDisabled}
                style={{
                  backgroundColor: "#FFF",
                  border: "1px solid #D0D5DD",
                  width: "100px",
                  display: "flex",
                  justifyContent: "center",
                  color: "#344054",
                  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                }}
                type="button"
              >
                <span>Cancel</span>
              </button>

              <button
                type="submit"
                disabled={isDisabled || frames.length == 0}
                style={{
                  backgroundColor:
                    isDisabled || frames.length == 0 ? "#AAAAAA" : "#F6552D",
                  border:
                    isDisabled || frames.length == 0
                      ? "none"
                      : "solid 1px #F6552D",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {isLoading ? (
                  // <div style={{ position: "relative", display: "flex" }}>
                  //   <CircularProgress
                  //     value={uploadProgress}
                  //     size={32}
                  //     className={`${style["load-publish"]}`}
                  //   />
                  //   <span
                  //     style={{
                  //       position: "absolute",
                  //       left: "25%",
                  //       top: "8px",
                  //       fontSize: "11px",
                  //     }}
                  //   >
                  //     {uploadProgress}
                  //   </span>
                  // </div>
                  <CircularProgressWithLabel
                    value={uploadProgress}
                    size={20}
                    className={`${style["load-publish"]}`}
                  />
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="24"
                    viewBox="0 0 23 24"
                    fill="none"
                  >
                    <path
                      d="M19.2087 3.79175L0.791992 9.75008L7.83366 13.0001L15.417 7.58341L10.0003 15.1667L13.2503 22.2084L19.2087 3.79175Z"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
                <span>Publish</span>
              </button>
            </div>
          </form>
        </div>
      </div>
      {showPopup && (
        <div className={style["popup"]}>
          <div className={style["popup-content"]}>
            <span>Please wait while your video is being published...</span>
          </div>
        </div>
      )}

      <CustomSnackbar
        openSnackbar={openSnackbar}
        closeSnackbar={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </div>
  );
};

export default UploadVideo;
